<template>
  <div>
    <vb-create-merchant @reload="reload()" />
    <vb-edit-merchant
      v-if="modalVisible"
      :id="id"
      @reload="reload()"
      @hide-modal="hideModal()" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #store="{ record }">
        {{ record.store?.name }}
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать запись</template>
            <a @click="showModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить запись</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination v-if="total > 10" v-model:current="query.page.value" :total="total" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import VbCreateMerchant from "@/views/click-merchant/create";
import VbEditMerchant from "@/views/click-merchant/edit";
import { useRoute, useRouter } from "vue-router";
import apiClient from "@/services/axios";
import { useStore } from "vuex";

let
  id = ref(0),
  dataSource = ref([]),
  imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: true,
    },
    {
      title: 'Филиал',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: true,
      slots: { customRender: 'store' },
    },
    {
      title: 'Merchant ID',
      dataIndex: 'merchant_id',
      key: 'merchant_id',
      sorter: true,
      slots: { customRender: 'merchant_id' },
    },
    {
      title: 'Service ID',
      dataIndex: 'service_id',
      key: 'service_id',
      sorter: true,
      slots: { customRender: 'service_id' },
    },
    {
      title: 'Merchant User ID',
      dataIndex: 'merchant_user_id',
      key: 'merchant_user_id',
      sorter: true,
      slots: { customRender: 'merchant_user_id' },
    },
    {
      title: 'Secret Key',
      dataIndex: 'secret_key',
      key: 'secret_key',
      sorter: true,
      slots: { customRender: 'secret_key' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showModal = (modelId) => {
    id.value = modelId;
    modalVisible.value = true
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  deleteModal = id => {
    /*
    Modal.confirm({
      title: 'Удаление роли',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`click-merchant/delete?id=${id}`)
          .then(response => {
            if (response) {
              message.success('Запись успешно удалена')
            }
          }).catch(err => console.log(err)).finally(() => {
            reload()
          });
      },
    });
     */
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/click-merchant', {
      params,
    }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      loading.value = false
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'click-merchant',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
