<template>
  <a-button
    class="editable-add-btn"
    @click="openModal"
    style="margin-bottom: 16px"
  >Добавить запись</a-button>

  <a-modal
    title="Добавить запись"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="create">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Филиал">
        <a-input-number v-model:value="model.store_id" />
      </a-form-item>
      <a-form-item label="Merchant ID">
        <a-input v-model:value="model.merchant_id" />
      </a-form-item>
      <a-form-item label="Service ID">
        <a-input v-model:value="model.service_id" />
      </a-form-item>
      <a-form-item label="Merchant User ID">
        <a-input v-model:value="model.merchant_user_id" />
      </a-form-item>
      <a-form-item label="Secret Key">
        <a-input v-model:value="model.secret_key" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, reactive} from "vue";

const emit = defineEmits(['reload'])

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  model = reactive({
    store_id: null,
    merchant_id: null,
    service_id: null,
    merchant_user_id: null,
    secret_key: null,
  }),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 };

const
  openModal = () => {
    modalVisible.value = true;
  },
  resetForm = () => {
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;
    return apiClient
      .post('/click-merchant/create', model)
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        emit('reload')
      })
  };
</script>
