<template>
  <a-modal
    title="Редактировать запись"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="update">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-form-item label="Филиал">
          <a-input-number v-model:value="model.store_id" />
        </a-form-item>
        <a-form-item label="Merchant ID">
          <a-input v-model:value="model.merchant_id" />
        </a-form-item>
        <a-form-item label="Service ID">
          <a-input v-model:value="model.service_id" />
        </a-form-item>
        <a-form-item label="Merchant User ID">
          <a-input v-model:value="model.merchant_user_id" />
        </a-form-item>
        <a-form-item label="Secret Key">
          <a-input v-model:value="model.secret_key" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, onMounted, reactive} from "vue";
import apiClient from "@/services/axios";
import Merchant from "@/models/Merchant";

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let fetching = ref(true),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  model = reactive({
    store_id: null,
    merchant_id: null,
    service_id: null,
    merchant_user_id: null,
    secret_key: null,
  }),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 };

const
  resetForm = () => {
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    return apiClient
      .put(`/click-merchant/update?id=${props.id}`, model)
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible = false;
        confirmLoading.value = false;
        emit('reload')
        emit('hide-modal')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

onMounted(() => {
  apiClient.get(`/click-merchant/view?id=${props.id}`).then(response => {
    if (response) {
      let data = new Merchant(response.data?.data)
      Object.assign(model, data)
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    fetching.value = false
  })
});

</script>
